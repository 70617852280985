
<template>
    <div>
        <select-employment-status
            :status="status"
            type="current"
            @update="update"
        ></select-employment-status>
    </div>
</template>
<script>
export default {
    name: "employment-info",
    components: {
        SelectEmploymentStatus: () =>
            import("../../Apply/SelectEmploymentStatus"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            status: this.applicant.meta.employment_info.status,
        };
    },
    watch: {
        applicant: {
            immediate: true,
            handler(applicant) {
                if (applicant.meta.employment_info.status.length > 0) {
                    this.$emit("update", {
                        complete: true,
                    });
                }
            },
        },
    },
    methods: {
        update({ status }) {
            this.$emit("update", {
                complete: true,
                applicant: {
                    meta: Object.assign(this.applicant.meta, {
                        employment_info: {
                            status,
                        },
                    }),
                },
            });
        },
    },
};
</script>

<style scoped>
</style>